import GuidUtils from '@/shared/utils/guid'

export default class Category {
  constructor({
    id,
    name,
    modalityId,
    modalityName,
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.name = name,
    this.modalityId = modalityId,
    this.modalityName = modalityName
  }
}