export default {
  name: 'NewRegisterCategory',

  props: {
    localTrainingId: String,
    modalityId: String,
    placeholder: String,
    title: String,
    data: Object,
    isNew: Boolean,
    optionsCategoryLocalTraining: Array,
    optionsCategoryModality: Array,
    onSelectLocalTraining: Function,
    onSelectModality: Function
  },

  computed: {
    state() {
      if (this.data && this.data.name) {
        return this.data.name.length >= 4
      }
    },
    disable() {     
      if (this.isNew === true) {
        if ((!this.data.localTrainingId || !this.data.modalityId) && !this.data.name)
          return true
        else {
          if (this.data.modalityId && this.data.localTrainingId && this.data.name)
            return this.data.name.length < 4
        }

      } else {
          if (this.data && this.data.name) {
            return this.data.name.length < 4
        }
      }
      return true
    }
  },

  methods: {
    backTo() {
      this.$router.go(-1)
    },

    save() {
      this.isNew 
        ? this.$emit('create', this.data)
        : this.$emit('update', this.data)
      },

    selectLocalTrainingId() {
      this.$emit('selectLocalTraining', this.data.localTrainingId)
    },

    selectModalityId() {
      this.$emit('selectModality', this.data.modalityId)
    }
  }
}